import React from 'react';
import { ExploreCard } from '@/app/components/cards/home';
import { PageWrapper, WrapperLabel } from '@/app/components/common';
import { Block } from '@/lib/model';
import { getContentsFromBlock } from '@/lib/api';

type Props = {
  block?: Block;
};

export function ExploreWrapper({ block }: Props) {
  if (!block) {
    return null;
  }
  const contents = getContentsFromBlock(block);
  if (!contents?.length) return null;
  return (
    <PageWrapper>
      <div
        className="w-full flex flex-col gap-2 sm:gap-4 xl:gap-6"
        data-nosnippet
      >
        <WrapperLabel title={block?.name} color="#4E4F4F" />

        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6 lg:gap-[34px]">
          {contents?.map((item, index) => {
            return (
              <ExploreCard
                key={`explore-wrapper-card-${item?.id}-${index}`}
                content={item}
              />
            );
          })}
        </div>
      </div>
    </PageWrapper>
  );
}
